<template>


    <div class="sy1" id="main">

        <div class="sy2">
            <div style="background: rgb(22 22 23 / 30%);width: 100%;height: 100%;">

                <div class="sy3">

                    <div style="font-size: 38px;color: #fff;align-self: center; ">
                        关于拔刀
                    </div>
                    <!-- <div>
                    数字权益商品集成式服务应用，提供模块化虚拟商 <br>
                    城搭建服务，帮助企业自助配置商品、定价 、UI<br>
                    模板及支付方式，快速创建属于自己的虚拟商城。
                </div> -->
                </div>
            </div>
        </div>

        <div class="sy5">
            <div class="sy4">


                <!-- <div>

            </div> -->


                <div class="sy6">
                    <h1>
                        关于拔刀
                    </h1>
                    <div>
                        自2022年成立至今，拔刀一直致力提供全面、优质的数字权益商品及服务。<br>
                        作为一家技术型公司，拔刀在连接数字权益商品提供商、消费场景和 <br>消费者。
                        向平台客户提供“一站式”的数字权益商品相关服务及增值服务，如商品销售、<br>
                        线上销售平台搭建及管理、营销及IT解决方案，受到上千家合作伙伴的青睐。
                    </div>
                </div>
            </div>

        </div>



        <div class="sy7">
            <div>
                <div class="sy8">
                    <h1>
                        联系我们
                    </h1>
                    <div style=" width: 228px;border-top: 2px solid #3788FF;">
                    </div>
                    <div class="sy9">
                        电话
                        <div>
                            15136250351
                        </div>
                    </div>
                    <div  class="sy9">
                        地址
                        <div>
                            河南省郑州市金水区金水路305号1号楼2单元25层西户
                        </div>
                    </div>
                </div>

                <div>
                    <img src="../assets/g2.png" alt="" srcset="">
                </div>

            </div>
        </div>













        <div class="all1">

            <div class="all2">

                <div class="all3">

                    <div>
                        拔刀科技
                    </div>

                    <div class="all4">
                        合作 ▪ 分享 ▪ 共赢
                    </div>
                </div>

                <div class="all5">
                    <div class="all6">
                        <!-- 周口拔刀网络科技有限公司 -->
                        周口拔刀网络科技有限公司
                    </div>
                    <div class="all4" style="display: flex;">
                        <div>
                            <i class="el-icon-office-building"></i>
                        </div>
                        <div>
                            河南省郑州市金水区金水路305号1号楼2单元 <br>25层西户
                        </div>
                    </div>
                    <div class="all4">
                        <i class="el-icon-phone-outline"></i>
                        <span>
                            15136250351
                        </span>
                    </div>
                </div>
            </div>

            <div class="all17">
                Copyright © 周口拔刀网络科技有限公司 <span @click="put">豫ICP备2023002840号-1</span>
            </div>
        </div>


    </div>


</template>
<script>

export default {
    name: 'introduce',
    components: {
    },

    data() {
        return {

        }
    },
    mounted() {
        document.getElementById("main").scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    },

    methods: {
        put() {
            window.open('https://beian.miit.gov.cn/')
        },
    }
}

</script>
<style lang="less" scoped>
.sy1 {
    width: 100%;
    height: 100%;
    // height: 3000px;

}

.sy2 {
    margin: 0 auto;
    // width: 1484px;
    height: 350px;
    background-image: url(../assets/u1.png);
    // background-repeat: repeat-x;
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.sy3 {
    font-size: 22px;
    color: #FFFFFF;
    font-weight: 600;
    // padding-left: 41%;
    // padding-top: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    // align-content: center;

}

.sy5 {
    width: 100%;

}

.sy4 {
    width: 1200px;
    margin: 0px auto;
}

.sy6 {
    text-align: center;
    padding: 78px 0px;

    h1 {
        color: #2E2E2E;
        padding: 20px 0px;
        font-size: 39px;
    }

    div {
        font-size: 16px;
        letter-spacing: 1px;
    }
}

.sy7 {
    background: #F7F8FB;
    width: 100%;

    padding: 60px 0px;

    >div {
        width: 1200px;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
    }
}

.sy8 {

    h1 {
        font-size: 32px;
        font-weight: 500;
        color: #000;
        margin-bottom: 17px;
    }

}
.sy9{
    color: #000;
    font-size: 15px;
    margin: 60px 0px;

    div{
        color: #979799;
    }
}
















.all1 {
    // margin-top: 60px;
    background: #262431;
    height: 300px;
}

.all2 {
    width: 1000px;
    height: 86%;
    color: #F1F1F8;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all3 {
    font-size: 18px;
    font-weight: 600;

}

.all4 {
    margin-top: 16px;

    i {
        padding-right: 10px;
    }

    span {
        font-size: 15px;
    }
}

.all5 {}

.all6 {
    font-size: 20px;
    font-weight: 600;
}

.all17 {
    font-size: 13px;
    color: #f1f1f8;
    text-align: center;
    opacity: 0.6;

    span {
        cursor: pointer;
    }
}
</style>
